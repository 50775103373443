import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FastField, Field, Formik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequestDotNet } from "../../../../../../../_utils/Axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Radio } from "@material-ui/core";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import CustomField from "../CustomField";
import { useFormikWizard } from "formik-wizard";
import { enqueueSnackbar } from "notistack";

export function CompanyInfo({ }) {
  // const applyFilter = (values) => {
  // };

  const intl = useIntl();
  const BuySchema = Yup.object().shape({});
  const { governorates } = useSelector(
    (state) => state.profileSlice,
    shallowEqual
  );
  const [list, setList] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const context = useFormikWizard();

  // useEffect(() => {
  //   console.log(context.values)
  //   let values = context.values.address
  //   values.msisdn = context.values.basic.msisdn
  //   console.log(values);

  //   sendRequestDotNet("Number/Buy ", values).then(
  //     (response) => {
  //       // if(response.data.statusCode.code==0){
  //         // myRefname.current.click();
  //         // goToPreviousStep()
  //       // }
  //       // setList(response.data.list)
  //       // setLoading(false)
  //     })
  // },[]);
  // console.log(context.values)
  function buy() {
    // console.log(context.values)
    setLoading(true)
    let values = context.values.address;
    values.msisdn = context.values.basic.msisdn;
    values.idNumber = values.idNumber.toString();
    values.contactNumber = values.contactNumber.toString();
    // console.log(values);

    sendRequestDotNet("Number/Buy ", values).then((response) => {
      setLoading(false)
      // console.log(response)
      if (!response.error) {

        if (response.data.statusCode.code == 0) {
          setSuccess(true)
          enqueueSnackbar(response.data.statusCode.message, {
            variant: "success"
          });
        }
        else {
          enqueueSnackbar(response.data.statusCode.message, {
            variant: "warning"
          });
        }
      }
    });
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            <FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.SUMMARY"></FormattedMessage>
          </Card.Title>

          <h6>
            <b>
              <FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.NUMBER"></FormattedMessage>
            </b> </h6>
          <p className="mb-0" style={{ fontWeight: "400", fontSize: "1.2rem" }}>
            <FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.NBR_SELECTED"></FormattedMessage>
            <b>
              <span style={{ color: "#043659" }}>
                {" "}
                {context.values.basic.msisdn}
              </span>
            </b>
          </p>
          <p style={{ fontWeight: "400", fontSize: "1.2rem" }}>
            <FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.PRICE:"></FormattedMessage>
            <b>
              <span style={{ color: "#043659" }}>
                {" "}
                {context.values.basic.price}
              </span>
            </b>
          </p>
          <h6 className="pt-5">
            <FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.CUSTU_INFO"></FormattedMessage>
          </h6>
          <p style={{ fontWeight: "400", fontSize: "1.2rem" }}>
            <FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.NAME"></FormattedMessage>
            <b>
              <span style={{ color: "#043659" }}>
                {" "}
                {context.values.address.customerName}
              </span>
            </b>
          </p>
          {/* <p style={{fontWeight:"400",fontSize:"1.2rem"}}>Name:<span style={{color:"#043659"}}>{context.values.customerName}</span></p> */}
          {!success && <Button
            disabled={loading} className="btn btn-warning mt-5" onClick={() => buy()}>
            <b>
              <FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.RESERVE_NBR"></FormattedMessage>
            </b>
            {loading && (
              <span className="mx-3 spinner spinner-white"></span>
            )}
          </Button>}
        </Card.Body>
      </Card>
    </>
  );
}
